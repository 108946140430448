<template>
  <div class="wrapper">
    
    <div class="slider__shapes">
        <img src="@/assets/img/slider_shape01.png" alt="shape">
        <img src="@/assets/img/slider_shape02.png" alt="shape">
        <img src="@/assets/img/slider_shape03.png" alt="shape">
        <img src="@/assets/img/slider_shape04.png" alt="shape">
    </div>
    <router-view />
  </div>
</template>

<script>
//import AdobeEdge from '@/plugins/edge.6.0.0.min';
export default {

  data() {
    return {
      
    };
  },

  mounted() {
    const warningTitleCSS = 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;';
    const warningDescCSS = 'font-size: 18px;';

    console.log('%cStop!', warningTitleCSS);
    console.log("%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable some feature or \"hack\" someone's account, it is a scam and will give them access to your account.", warningDescCSS);
    console.log('%cPlease close this window', warningDescCSS);
    /* window.Echo.channel('test') // Reemplaza 'test' con el nombre de tu canal
      .listen('TestEvent', (e) => {
        console.log(e.message); // Maneja el evento como desees
      }); */
	  

  },
  created() {
	/* $.getScript('./edge.6.0.0.min.js', () => {
			AdobeEdge.loadComposition('./back-animation', 'EDGE-36135666', {
				scaleToFit: "none",
				centerStage: "none",
				minW: "0px",
				maxW: "undefined",
				width: "1920px",
				height: "600px"
			}, {
				"dom": {}
			}, {
				"dom": {}
			});
		}); */
  },
  
  methods: {

  },
};
</script>
<style scoped>
.slider__shapes {
    opacity: 0.27;
}

.slider__shapes img:nth-child(1) {
    left: 23%;
    top: 17%;
    animation-delay: 0.2s;
}

.slider__shapes img {
    position: absolute;
    animation: shapeBlinker 1.5s linear infinite;
    opacity: 0;
    z-index: -1;
}

@media (max-width: 991.98px) {
     .slider__shapes img:nth-child(1) {
         left: 13%;
         top: 12%;
     }
 }

 @media (max-width: 767.98px) {
     .slider__shapes img:nth-child(1) {
         left: 11%;
         top: 11%;
     }
 }

 .slider__shapes img:nth-child(2) {
     left: 29%;
     bottom: 66%;
     animation-delay: 0.4s;
 }

 @media (max-width: 991.98px) {
     .slider__shapes img:nth-child(2) {
         left: 12%;
     }
 }

 @media (max-width: 767.98px) {
     .slider__shapes img:nth-child(2) {
         left: 10%;
         bottom: 55%;
     }
 }

 .slider__shapes img:nth-child(3) {
     right: 25%;
     top: 15%;
     animation-delay: 0.2s;
 }

 @media (max-width: 991.98px) {
     .slider__shapes img:nth-child(3) {
         right: 23%;
         top: 15%;
     }
 }

 @media (max-width: 767.98px) {
     .slider__shapes img:nth-child(3) {
         right: 10%;
         top: 17%;
     }
 }

 .slider__shapes img:nth-child(4) {
     right: 12%;
     top: 23%;
     animation-delay: 0.6s;
 }

 @media (max-width: 1500px) {
     .slider__shapes img:nth-child(4) {
         right: 12%;
         top: 28%;
     }
 }

 @media (max-width: 991.98px) {
     .slider__shapes img:nth-child(4) {
         right: 20%;
         top: 35%;
     }
 }

 @media (max-width: 767.98px) {
     .slider__shapes img:nth-child(4) {
         right: 14%;
         top: 33%;
     }
 }

@-webkit-keyframes shapeBlinker {
     50% {
         opacity: 0.59;
     }
 }

 @keyframes shapeBlinker {
     50% {
         opacity: 0.59;
     }
 }


</style>
