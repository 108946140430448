import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        'footer': {
            tlContent: 'Content',
            statis: 'Statistics',
            energy: 'Energy',
            rfProm: 'Referral Program',
            getSt: 'Getting started',
            whyUs: 'Why Sephyr',
            terms: 'Terms of Use',
            vulne: 'Vulnerability Disclosure Policy'
        },
        'main': {
            your: 'Your',
            mining: 'Mining',
            ecosystem: 'Ecosystem',
            sub1: 'Hassle-free mining. Predictable revenue stream. Maximum performance and speed.',
            activeMiners: 'Active miners',
            workers: 'Workers',
            mobileUsers: 'Mobile users',
            telegramSus: 'Telegram subscribers'
        },
        '404': {
            Fr1: 'We are sorry, but the page cannot be found.',
            Fr2: 'The link may be broken, or the page has been removed.',
            btn: 'Back to Main'
        },
        pricing: 'Pricing',
        soporte: 'Support',
        contacts: 'Contacts',
        community: 'Community',
        forum: 'Forum',
        other: 'Other',
        knowledge: 'Knowledge base',
        overview: 'Overview',
        features: 'Features',
        more: 'More',
        increase: 'Increase your income with Sephyr. Invite your friends and earn real cryptocurrency!',
        getRef: 'Get your referral link',
        signIn: 'Sign In',
        rfProm: 'Referral Program',
        getSt: 'Getting started',
        guides: 'User guides',
        showAll: 'Show all articles',
        topForum: 'Top discussions, announcements, help',
        blog: 'Blog',
        newsArt: 'News and Articles',
        'login': {
            lTitle: 'Log in to your Sephyr Account',
            forgot: 'Forgot password?',
            haveAcc: 'Don\'t have an account yet?',
            register: 'Register',
            lgBtn: 'Log in',
            userOrEmail: 'Login or email',
            pass: 'Password',
            recover: 'Enter email to recover password',
            confirm: 'Confirm',
            return: 'Return to',
            cuenta: 'Do you have an account yet?',
            inicia: 'Log in'
        },
        'recover': {
            userOrEmail: 'Email',
            alert: 'If your account exists, you will receive an email shortly with further instructions.'
        },
        'register': {
            termsFr: 'By clicking Register, you agree to the',
            terms: 'Terms',
            email: 'Email',
            registerBtn: 'Register',
            username: 'Username',
            title: 'Create new account',
            password_confirmation: 'Password Confirmation'
        },
        'reset': {
            hint: 'Password must contain at least 8 characters. It is recommended to use uppercase and lowercase letters and numbers. It is also advisable to use some of the following symbols:',
            title: 'Update Password',
            subTitle: 'You need to change your password.'
        },
        'changePw': {
            pw: 'Password',
            pwcurrent: 'Current Password',
            newpw: 'New Password',
            pwconfirm: 'Confirm New Password',
            btn: 'Update Password'
        },
        'headeAc': {
            events: 'Events',
            account: 'Account',
            password: 'Password',
            authenticator: 'Authenticator',
            dashboard: 'Panel'
        },
        'headedash': {
            events: 'Events',
            account: 'Account',
            withdraw: 'Withdraw',
            funds: 'Your Funds',
            dashboard: 'Dashboard',
            referrals: 'Referrals',
            new: 'New',
            publicstats: 'Public Stats',
            SignOut: 'Sign Out',
            auto: 'Auto Refresh Page Timer',
            newfarm: 'Create new farm'
        },
        'dash': {
            farms: 'Farms',
            workers: 'Workers',
            consumption: 'Mining power',
            dailycost: 'Daily cost',
            funds: 'Your Funds',
            favorites: 'Favorites',
            farmname: 'Farm name',
            realTime: 'Real Time Balance',
            remaining: 'Remaining Days'
        },
        'acc': {
            frecuency: 'Mining power',
            noContracts: '',
            noEvents: 'No events yet',
            aps: 'Account Profile Settings',
            lang: 'Language',
            profile: 'Profile',
            prhint: 'Your login is your identity on Sephyr and is used to sign in.',
            user: 'Username',
            confirm: 'Your email is confirmed',
            balance: 'Balance',
            free: 'Free',
            daily: 'Daily price',
            workers: 'Workers',
            online: 'Online',
            previous: 'Previous',
            next: 'Next',
            refHint: 'Know about our referral program',
            menu: 'Menu',
            joinS: 'Join Sephyr cloud mining platform and get stable payouts.',
            mainTl: 'Sephyr best cloud mining <span class="theme-text-highlight">platform</span>',
            startMn: 'Start mining',
            revenue: 'Revenue',
            hashrate: 'Your Investment',
            daily: 'Daily earnings',
            price: 'BTC Price',
            net: 'Network hashrate',
            day: 'day',
            payment: 'Payment time',
            treshold: 'Payment threshold',
            difficulty: 'Current difficulty',
            to: 'to',
            manage: 'Manage all farms <br> <span class="theme-text-highlight">efficiently</span> with Sephyr',
            description: 'Sephyr is the cloud mining platfom that helps to keep your crypto farms at their peak and monitor their info in one place.',
            profitable: '<span class="theme-text-highlight">Profitable</span> cloud mining for BTC, LTC, DOGE, DASH, BCH, ETC',
            mainSub: 'We rent high-performance mining rigs and ASICs without the energy cost.',
            invite: 'Invite friends — increase your income',
            inviteHint: 'Share the link with your mates and <span class="theme-text-highlight">get 10%</span> from your referral payments each time they deposit.',
            linkRef: 'Get Your Referral Link',
            getAcc: 'Get access to <span class="theme-text-highlight">Sephyr for free</span> in 3 easy steps:',
            createAcc: 'Create a Sephyr account',
            chooseAcc: 'Choose what currency want to mine',
            receiveAcc: 'Receive your revenue',
            startMining: 'BTC mining start with Sephyr',
            someEq: 'These are some of our equipment in our <span class="theme-text-highlight">Sephyr farm</span>',
            asicHub: '$0 for ASIC Hub',
            asicHubSub: 'When mining BTC on Sephyr',
            asicHubDesc: 'With ASIC Hub, you can add hundreds of ASICs in only a few clicks.',
            fee: '0% transaction fee',
            feeSub: 'We fully cover transaction costs',
            feeDesc: 'Let us take care of all transaction fees so that you can get even higher revenue when mining BTC on Sephyr',
            dailyP: 'Daily payouts',
            minimalP: 'Minimal payout',
            fppsSub: 'Mining pool reward type',
            fppsDesc: 'FPPS is one of the fairest reward types. You get rewarded for every valid share sent to the pool regardless of when you have joined Sephyr. Moreover, your earnings are higher due to distributed transaction fees'
        },
        'faq': {
            question1: 'How can I get a referral link?',
            answer1: 'Each registered member can join our referral system and use a single referral link. Once you’ve registered your account, go to <b>Settings &gt; Referrals</b>. You can see your personal referral link there.',
            question2: 'How can I check how many referrals I invited and what is my profit?',
            answer2: 'Login to your Sephyr account, pick <b>Referrals</b> in the farm menu. <br>Here you can see the following details: <ul><li>Referral balance - the total amount of money you have earned since participating in the referral program.</li><li>Referral transactions history - the reward payouts you’ve got from us.</li></ul>',
            question3: 'What exactly do I receive rewards for?',
            answer3: 'You receive 10% of all payments your referrals make',
            question4: 'In what currency will I receive rewards?',
            answer4: 'The payments are sent to the private wallet you specify. You can also withdraw the money to your balance, at any time and in any amount (fully or partially).',
            question5: 'How often will I receive payments for the activities of my referrals?',
            answer5: 'Payments are reflected immediately the amount of your referral is confirmed.',
            question6: 'How many referrals I can bring onto Sephyr?',
            answer6: 'There are no limits.',
            question7: 'Can the terms of the referral program change?',
            answer7: 'Only in case if you have a very large amount of referrals. In such cases, we may contact you with a personal offer',
        },
        'ref': {
            how: 'How it works',
            partner: 'Partnership with Sephyr',
            increase: 'Increase your income with Sephyr.',
            invite: 'Invite your friends and earn real cryptocurrency!',
            getRef: 'Get your referral link',
            earnings: 'Daily earnings for your referred users',
            every: 'Earn every time your referral makes a deposit',
            promo: 'Invite new users using referral link or promocode',
            yourLink: 'Get your referral link in account settings',
            porcentaje: 'Get 10% from your referrals payments',
            earnMoney: 'Earn money each every day till the friend is using Sephyr',
            unlimited: 'Unlimited number of referrals, no hidden restrictions',
            pricingDesc: 'Some of our prices',
        },
        'precios': {
            our: 'Our',
            daily: 'everyday',
            custom: 'Custom',
            selectValue: 'You can select a custom value',
            limited: 'Limited to mine a single coin',
            limitedFeatures: 'Limited features',
            community: 'Community support',
            join: 'Join now',
            payNow: 'Pay Now',
            allFeatures: 'All features',
            miningMore: 'Mining more than 3 coins',
            multiple: 'Multiple farms management',
            emailSupport: 'Email &amp; Community support',
            getStarted: 'Get Started',
            fastest: 'Fastest withdraw',
            allRegular: 'All regular features',
            additional: 'Additional individual features',
            individual: 'Individual support',
            basic: 'Basic',
            standard: 'Standard',
            premium: 'Premium',
            after: 'After ',
            hours: '24 hours',
            hours2: '48 hours',
            min: 'Min Deposit:',
            max: 'Max Deposit:',
            days: 'days',
            withdraw: 'Withdrawal:',
            returned: 'Principal: Returned ',
            retorno: 'Principal: Returned',
            unlimited: 'Unlimited',
            basicPlan: 'Basic Plan',
            standardPlan: 'Standard Plan',
            premiumPlan: 'Premium Plan',
            deposits: 'Latest Deposits',
            withdrawals: 'Latest Withdrawals',
            statistics: 'Live Statistics'
        },
        index: 'Main',
        backToindex: 'Back to main site',
        advanced: 'Advanced calculator',
        profit: 'Your profit',
        choose: 'Choose hardware',
        hash: 'Total hashrate',
        powerC: 'Power consumption',
        next_payment: 'Next Payment',
        cost: 'Electr. costs per kWh',
        note: 'Please note that these values are based on past performance when the exchange rate was',
        noteHint: 'Actual values may vary',
        dayC: 'Day',
        monthC: 'Month',
        estEar: 'Est. earnings',
        per: 'Per',
        elecCost: 'Electr. costs',
        estPro: 'Est. profit',
        youPay: 'You would pay',
        forThs: 'for',
        calculator: 'Mining Calculator',
        hours: 'Hours',
        days: 'Days',
        'notify': {
            ntTl: 'Notifications Settings',
            start: 'Start a new chat with this bot <a class="b016bfaabea2d974a696" href="https://t.me/SephyrBot" target="_blank" rel="noopener noreferrer">t.me/SephyrBot</a> Send this command to bot <code class="a1adeafd680cd987869d">/user</code> The bot will reply with your CODE which you should enter in the field on the right. For example, \'0123456789\'.',
            subs: 'Subscribe',
            code: 'Enter code to confirm',
            notifications: 'Notifications',
            ceroNoti: 'No notifications yet'
        },
        'details': {
            workers: 'Workers',
            frecuency: 'Frecuency',
            balance: 'Balance',
            cost: 'Cost',
            total: 'Total profit at end'
        },
        'deposit': {
            refill: 'Refill Deposit',
            amountRe: 'Amount to refill',
            pay: 'Pay',
            amountTr: 'Amount to transfer',
            transfer: 'Transfer funds to others',
            username: 'Username or',
            payments: 'Payments History',
            noTransaction: 'No transactions yet',
            farms: 'Farms',
            payer: 'Payer',
            balance: 'Balance',
            daily: 'Daily Payment'
        },
        'dashboard': {
            ceroContract: 'No farms yet'
        },
        'withdraw': {
            withdraw: 'Withdraw',
            balance: 'Withdraw Balance',
            address: 'Bitcoin Address',
            save: 'Save',
            warning: 'Withdraw info',
            hint: 'For every withdraw we send you a email with 6 digits code to avoid fraud.',
            cantidad: 'Balance',
            history: 'Withdraw history',
            minimo: 'Minimun withdraw',
            requestCode: 'Request Code',
            amount: 'Amount',
            code: 'Verification code',
            coinAmount: 'Coin | Amount',
            previousWithdrawals: 'Previous withdrawals'

        },
        'referral': {
            balance: 'Referral Rewards',
            link: 'Referral Link',
            linkHint: 'Share this link with others. They will be marked as your referrals after registering via your link. After they register you get 10%',
            noRewards: 'You don\'t have any referral rewards yet.',
            copy: 'Copy to Clipboard',
            history: 'Referral history',
            noReferrals: 'No referrals yet',
            username: 'Username',
            payments: 'Payments History',
            noPayments: 'Don\'t have any payment yet'
        },
    },
    'es': {
        'referral': {
            balance: 'Recompensas de referidos',
            link: 'Enlace de referido',
            linkHint: 'Comparte este enlace con otros. Se marcarán como sus referencias después de registrarse a través de su enlace. Después de que se registran, obtienes un 10%',
            noRewards: 'Todavía no tienes ninguna recompensa por referidos.',
            copy: 'Copiar',
            history: 'Historial de referidos',
            noReferrals: 'No Tienes Referidos aún',
            username: 'Nombre de usuario',
            payments: 'Historial de pagos',
            noPayments: 'No tienes pagos aún'
        },
        'withdraw': {
            withdraw: 'Retiro',
            balance: 'Saldo para retirar',
            address: 'Dirección Bitcoin',
            save: 'Guardar',
            warning: 'Información de retiro',
            hint: 'Por cada retiro te enviamos un email con un código de 6 dígitos para evitar fraudes.',
            cantidad: 'Saldo',
            history: 'Historial de retiros',
            minimo: 'Retiro mínimo',
            requestCode: 'Solicitar Codigo',
            amount: 'Monto',
            code: 'Codigo',
            coinAmount: 'Moneda | Monto',
            previousWithdrawals: 'Retiros anteriores'

        },
        'details': {
            workers: 'Trabajadores',
            frecuency: 'Frecuencia',
            balance: 'Saldo',
            cost: 'Valor',
            total: 'Beneficio total al final'
        },
        'deposit': {
            refill: 'Depósito de recarga',
            amountRe: 'Cantidad a recargar',
            pay: 'Pagar',
            amountTr: 'Cantidad a transferir',
            transfer: 'Transferir fondos a otros',
            username: 'Nombre de usuario o',
            payments: 'Historial de pagos',
            noTransaction: 'Aún no hay transacciones',
            farms: 'Granjas',
            payer: 'Pagador',
            balance: 'Saldo',
            daily: 'Pago Diario'
        },
        'notify': {
            ntTl: 'Configuracion de notificaciones',
            start: 'Iniciar un nuevo chat con este bot <a class="b016bfaabea2d974a696" href="https://t.me/SephyrBot" target="_blank" rel="noopener noreferrer">t.me/SephyrBot</a> Envía este comando al bot <code class="a1adeafd680cd987869d">/user</code> El bot te responderá con tu CÓDIGO que deberás introducir en el campo de la derecha. Por ejemplo, \'0123456789\'.',
            subs: 'Suscribirse',
            code: 'Introduce el código para confirmar',
            notifications: 'Notificaciones',
            ceroNoti: 'No tienes notificaciones aún'
        },
        index: 'Inicio',
        backToindex: 'Volver a la página principal',
        advanced: 'Calculadora avanzada',
        profit: 'Su ganancia',
        choose: 'Selecciona equipo',
        hash: 'Tasa de hash total',
        powerC: 'Consumo de energía',
        next_payment: 'Próximo pago',
        cost: 'Costo eléctrico por kWh',
        note: 'Tenga en cuenta que estos valores se basan en el rendimiento anterior cuando el tipo de cambio era',
        noteHint: 'Los valores reales pueden variar',
        dayC: 'Diario',
        monthC: 'Mensual',
        estEar: 'Ganancias Est.',
        per: 'Por',
        hours: 'Horas',
        days: 'Dias',
        elecCost: 'Costo eléctrico',
        estPro: 'Ganancia Est.',
        youPay: 'Pagarías',
        forThs: 'por',
        calculator: 'Calculadora de minado',
        'precios': {
            daily: 'diarios',
            custom: 'Personalizado',
            selectValue: 'Puedes seleccionar un valor personalizado',
            limited: 'Limitado para minar una sola moneda',
            limitedFeatures: 'Caracteristicas limitadas',
            community: 'Soporte de la comunidad',
            join: 'Únete ahora',
            payNow: 'Pagar ahora',
            allFeatures: 'Todas las características',
            miningMore: 'Minar más de 3 monedas',
            multiple: 'Gestión de múltiples granjas',
            emailSupport: 'Email y soporte de la comunidad',
            getStarted: 'Empezar',
            fastest: 'Retiros más rápidos',
            allRegular: 'Todas las características anteriores',
            additional: 'Características individuales adicionales',
            individual: 'Soporte individual',
            basic: 'Básico',
            standard: 'Estándar',
            premium: 'Premium',
            after: 'Después de ',
            hours: '24 horas',
            hours2: '48 horas',
            min: 'Depósito mínimo:',
            max: 'Depósito máximo:',
            days: 'días',
            withdraw: 'Retiro:',
            returned: 'Principal: Retorno ',
            unlimited: 'Ilimitado',
            basicPlan: 'Plan Básico',
            standardPlan: 'Plan Estándar',
            premiumPlan: 'Plan Premium',
            deposits: 'Ultimos Depositos',
            withdrawals: 'Ultimos Retiros',
            statistics: 'Estadísticas en vivo',
            our: 'Nuestro'
        },
        'ref': {
            how: 'Cómo funciona',
            partner: 'Asociación con Sephyr',
            increase: 'Incrementa tus ingresos con Sephyr.',
            invite: '¡Invita a tus amigos y gana criptomonedas reales!',
            getRef: 'Obtenga su enlace de referidos',
            earnings: 'Ganancias diarias por sus usuarios referidos',
            every: 'Gane cada vez que sus referidos hagan un depósito',
            promo: 'Invite a nuevos usuarios usando su enlace de referencia o un código de promoción',
            yourLink: 'Obtenga su enlace de referencia en la configuración de la cuenta',
            porcentaje: 'Obtenga un 10% de los pagos de sus referidos',
            earnMoney: 'Gana dinero todos los días hasta que su amigo use Sephyr',
            unlimited: 'Número ilimitado de referidos, sin restricciones ocultas',
            pricingDesc: 'Algunos de nuestros precios',
        },
        'faq': {
            question1: 'Cómo puedo obtener un enlace de referido?',
            answer1: 'Cada miembro registrado puede unirse a nuestro sistema de referencia y usar un solo enlace de referencia. Una vez que haya registrado su cuenta, vaya a <b>Configuración &gt; Referencias</b>. Puede ver su enlace de referencia personal allí.',
            question2: 'Cómo puedo verificar cuántos referidos invité y cuál es mi ganancia?',
            answer2: 'Inicie sesión en su cuenta de Sephyr, elija <b>Referidos</b> en el menú de la granja. <br>Aquí puede ver los siguientes detalles: <ul><li>Saldo de referidos: la cantidad total de dinero que ha ganado desde que participó en el programa de referidos.</li><li>Historial de transacciones de referidos: las recompensas que recibe de nosotros.</li></ul>',
            question3: 'Qué recompesas recibo exactamente?',
            answer3: 'Recibes el 10% de todos los pagos que hacen tus referidos',
            question4: 'En qué moneda recibiré las recompensas?',
            answer4: 'Los pagos se envían a la billetera privada que especifique. También puede retirar el dinero a su saldo, en cualquier momento y en cualquier cantidad (total o parcialmente)',
            question5: 'Con qué frecuencia recibiré pagos por las actividades de mis referidos?',
            answer5: 'Los pagos se ven reflejados inmediatamente es confirmado el monto de tu referido',
            question6: 'Cuántos referidos puedo traer a Sephyr?',
            answer6: 'No hay límites.',
            question7: 'Pueden cambiar los términos del programa de referidos?',
            answer7: 'Solo en caso de que tenga una gran cantidad de referidos. En tales casos, podemos comunicarnos con usted con una oferta personal.',
        },
        'acc': {
            frecuency: 'Poder de minado',
            noEvents: 'No tienes eventos aún',
            aps: 'Configuración del perfíl',
            lang: 'Idioma',
            profile: 'Perfíl',
            prhint: 'Su inicio de sesión es su identidad en Sephyr, se utiliza para iniciar sesión.',
            user: 'Nombre de usuario',
            confirm: 'Su correo electrónico está confirmado',
            balance: 'Saldo',
            free: 'Gratis',
            daily: 'Precio diario',
            workers: 'Trabajadores',
            online: 'En línea',
            previous: 'Anterior',
            next: 'Siguiente',
            refHint: 'Conoce nuestro programa de referidos',
            menu: 'Menú',
            joinS: 'Únase a la plataforma de minería en la nube Sephyr y obtenga pagos estables.',
            mainTl: 'Sephyr la mejor <span class="theme-text-highlight">plataforma</span> de minería en la nube',
            startMn: 'Empieza a minar',
            revenue: 'Ganancia',
            hashrate: 'Su Inversión',
            daily: 'Ganancias diarias',
            price: 'Precio BTC',
            net: 'Tasa de hash en la red',
            day: 'día',
            payment: 'Horas de pago',
            treshold: 'Pago mínimo',
            difficulty: 'Dificultad actual',
            to: 'a',
            manage: 'Administre todas <br> sus granjas de manera <span class="theme-text-highlight">eficiente</span> con Sephyr',
            description: 'Sephyr es la plataforma de minería en la nube que ayuda a mantener sus granjas criptográficas en su punto máximo y monitorear su información en un solo lugar.',
            profitable: '<span class="theme-text-highlight">Minería en la nube</span> rentable para BTC, LTC, DOGE, DASH, BCH, ETC',
            mainSub: 'Alquilamos equipos de minería y ASICs de alto rendimiento sin el costo de la energía.',
            invite: 'Invita amigos - aumenta tus ingresos',
            inviteHint: 'Comparta el enlace con sus compañeros y <span class="theme-text-highlight">obtenga un 10%</span> de los pagos de sus referidos cada vez que depositen.',
            linkRef: 'Obtenga su enlace de referido',
            getAcc: 'Obtenga acceso a <span class="theme-text-highlight">Sephyr de forma gratuita</span> en 3 sencillos pasos:',
            createAcc: 'Crear una cuenta en Sephyr',
            chooseAcc: 'Elige qué moneda quieres minar',
            receiveAcc: 'Recibe tus ingresos',
            startMining: 'La minería BTC comienza con Sephyr',
            someEq: 'Estos son algunos de nuestros equipos en nuestra granja <span class="theme-text-highlight">Sephyr</span>',
            asicHub: '$0 para ASIC Hub',
            asicHubSub: 'Al minar BTC en Sephyr',
            asicHubDesc: 'Con ASIC Hub, puede agregar cientos de ASIC en solo unos clicks.',
            fee: '0% en costos transacción',
            feeSub: 'Cubrimos completamente los costos de transacción',
            feeDesc: 'Permítanos encargarnos de todas las tarifas de transacción para que pueda obtener ingresos aún mayores al minar BTC en Sephyr',
            dailyP: 'Pagos diarios',
            minimalP: 'Pago mínimo',
            fppsSub: 'Tipo de recompensa del pool de minería',
            fppsDesc: 'FPPS es uno de los tipos de recompensa más justos. Recibe una recompensa por cada acción válida enviada al grupo, independientemente de cuándo se haya unido a Sephyr. Además, sus ganancias son más altas debido a las tarifas de transacción distribuidas'
        },
        'dash': {
            farms: 'Granjas',
            workers: 'Trabajadores',
            consumption: 'Poder de minado',
            dailycost: 'Costo Diario',
            funds: 'Sus Fondos',
            favorites: 'Favoritas',
            farmname: 'Nombre de la granja',
            realTime: 'Saldo en tiempo real',
            remaining: 'Días restantes'
        },
        'headedash': {
            events: 'Notificaciones',
            account: 'Cuenta',
            withdraw: 'Retiros',
            funds: 'Sus Fondos',
            dashboard: 'Panel',
            referrals: 'Referidos',
            new: 'Nuevo',
            publicstats: 'Estadísticas Públicas',
            SignOut: 'Salir',
            auto: 'Temporizador de actualización automática',
            newfarm: 'Crear nueva granja'
        },
        'headeAc': {
            events: 'Eventos',
            account: 'Cuenta',
            password: 'Contraseña',
            authenticator: 'Autenticador',
            dashboard: 'Panel'
        },
        'changePw': {
            pw: 'Contraseña',
            pwcurrent: 'Contraseña actual',
            newpw: 'Nueva contraseña',
            pwconfirm: 'Confirmar nueva contraseña',
            btn: 'Actualizar contraseña'
        },
        'reset': {
            hint: 'La contraseña debe contener al menos 8 caracteres. Se recomienda utilizar letras mayúsculas, minúsculas y números. También es recomendable utilizar algunos de los siguientes símbolos:',
            title: 'Actualizar contraseña',
            subTitle: 'Necesita cambiar su contraseña.'
        },
        'register': {
            termsFr: 'Al hacer clic en registrarse, usted acepta los',
            terms: 'Terminos',
            email: 'Email',
            registerBtn: 'Registro',
            username: 'Usuario',
            title: 'Crear cuenta nueva',
            password_confirmation: 'Confirmar contraseña'
        },
        'recover': {
            userOrEmail: 'Email',
            alert: 'Si su cuenta existe, recibirá un correo electrónico en breve con más instrucciones.'
        },
        'footer': {
            tlContent: 'Contenido',
            statis: 'Estadísticas',
            energy: 'Energía',
            rfProm: 'Programa de Referidos',
            getSt: 'Empezar',
            whyUs: 'Porque Sephyr',
            terms: 'Condiciones de uso',
            vulne: 'Política de divulgación de vulnerabilidades'
        },
        'login': {
            lTitle: 'Inicie sesión en su cuenta Sephyr',
            forgot: 'Ha olvidado su contraseña?',
            haveAcc: 'Aún no tienes una cuenta?',
            register: 'Regístrate',
            lgBtn: 'Iniciar Sesión',
            userOrEmail: 'Usuario o email',
            pass: 'Contraseña',
            recover: 'Ingrese el correo electrónico para recuperar la contraseña',
            confirm: 'Confirmar',
            return: 'Volver a',
            cuenta: 'Ya tienes una cuenta?',
            inicia: 'Inicia Sesión'
        },
        'main': {
            your: 'Tu',
            mining: 'de Minado',
            ecosystem: 'Ecosistema',
            sub1: 'Minería sin problemas. Flujo de ingresos predecible. Máximo rendimiento y velocidad.',
            activeMiners: 'Mineros Activos',
            workers: 'Trabajadores',
            mobileUsers: 'Usuarios móviles',
            telegramSus: 'Suscriptores de telegram'
        },
        '404': {
            Fr1: 'Lo sentimos, pero no se puede encontrar la página.',
            Fr2: 'El enlace puede estar roto o la página ha sido eliminada.',
            btn: 'Volver'
        },
        'dashboard': {
            ceroContract: 'No tienes ninguna granja aún'
        },
        pricing: 'Precios',
        soporte: 'Soporte',
        contacts: 'Contacto',
        community: 'Comunidad',
        forum: 'Foro',
        other: 'Otros',
        knowledge: 'Base de conocimientos',
        overview: 'Descripción',
        features: 'Características',
        more: 'Mas',
        increase: 'Incrementa tus ingresos con Sephyr. ¡Invita a tus amigos y gana criptomonedas reales!',
        getRef: 'Obtenga su enlace de referidos',
        signIn: 'Iniciar',
        rfProm: 'Programa de Referidos',
        getSt: 'Empezar',
        guides: 'Guias de Usuario',
        showAll: 'Mostrar todos los artículos',
        topForum: 'Principales debates, anuncios, ayuda',
        blog: 'Blog',
        newsArt: 'Noticias y Artículos',

    }
};


const i18n = new VueI18n({
    locale: (localStorage.getItem('lang') || 'en'),
    //locale: 'en', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages, // set locale messages
});

export default i18n;