import axios from 'axios'

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        saldo: 0,
    },
    mutations: {
        EL_TOKEN(state, token) {
            state.token = token
        },
        EL_USUARIO(state, data) {
            state.user = data
        },
        setSaldo(state, nuevoSaldo) {
            state.saldo = nuevoSaldo;
        },
    },
    getters: {
        autenticado(state) {
            return state.token && state.user
        },
        usuario(state) {
            return state.user
        },
        saldo(state) {
            return state.saldo
        },
    },
    actions: {
        async fetchSaldo({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'obtener-saldo');
                const nuevoSaldo = response.data.balance; // Ajusta según la estructura de la respuesta
                commit('setSaldo', nuevoSaldo);

            } catch (error) {
                console.error('Error al obtener el saldo:', error);
            }
        },
        async logout(context) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'logout');
            localStorage.removeItem('token');
            context.commit('EL_USUARIO', null);
            context.commit('EL_TOKEN', null);
            return res.data
        },
        async signIn({ dispatch }, credenciales) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'auth/login', credenciales)
            return dispatch('intento', res.data.token)
        },
        async passwordChange({ dispatch }, data) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'changePassword', data)
            return res.data
        },
        async sendRegisterRequest({ dispatch }, data) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'auth/register', data)
            return res.data
        },
        async sendReferralRequest({ dispatch }, data) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'auth/doReferral', data)
            return res.data
        },
        async intento({ dispatch, commit, state }, token) {
            if (token) {
                commit('EL_TOKEN', token)
            }

            if (!state.token) {
                return;
            }

            try {
                let res = await axios.get(process.env.VUE_APP_API_URL + 'me')
                commit('EL_USUARIO', res.data)
                dispatch('fetchSaldo')
            } catch (e) {
                commit('EL_USUARIO', null)
                commit('EL_TOKEN', null)
            }
        }
    }
};