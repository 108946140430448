import axios from "axios";

axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    if (error.response.status === 401 && error.response.error === 'Unauthorized') {

        console.log(error.response.status + 'interceptor 11')
    }

    /* if(error.response.status === 500){
      store.dispatch('user/logout');
    } */

    return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
    if (config.method === 'get') {
        if (typeof config.params !== 'undefined') {
            config.params = Object.assign(config.params, {
                api_key: '1439d1d5306abcb864f054710879ccef'
            })
        } else {
            config.params = {
                api_key: '1439d1d5306abcb864f054710879ccef'
            }
        }
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});