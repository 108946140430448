import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import store from "./store";
import { store } from "./store/index";
import './helpers/axiosInterceptors';
//import axios from "axios";
import $ from 'jquery';
//import jQuery from 'jquery';
window.jQuery = $;
window.$ = $;
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment-timezone'
import i18n from '@/plugins/i18n';
import 'animate.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';


import './assets/css/main.css'
import './assets/css/all.css'

require('./store/subscriber')
moment.tz.setDefault('Etc/UTC')
import Echo from "laravel-echo";
import Pusher from "pusher-js";

Vue.use(VueToast);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


Vue.config.productionTip = false;
window.Pusher = Pusher;
const token = localStorage.getItem('token')


/* window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  cluster: 'us2',
  wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
  wsPort: 6001,
  wssPort: 6001,
  forceTLS: false,
  encrypted: false,
  disableStats: true,
  enabledTransports: ['ws'],
}); */


let app = '';
store.dispatch('user/intento', localStorage.getItem('token')).then(() => {
  if (!app) {
    app = new Vue({
      router,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});