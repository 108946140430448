import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* const guest = (to, from, next) => {
  if (!localStorage.getItem("token")) {
    return next();
  } else {
    return next("/");
  }
}; */
/* const auth = (to, from, next) => {
  if (localStorage.getItem("token")) {
    return next();
  } else {
    return next("/login");
  }
}; */

/* const auth = (to, from, next) => {
  if (localStorage.getItem("token")) {
    return next();
  } else {
    return next("/login");
  }
}; */

const routes = [
  {
    path: "*",
    component: () => import(/* webpackChunkName: "pagenotfound" */ "../views/Front/PageNotFound.vue")
  },
  {
    path: "/",
    name: "Index",
    component: () => import(/* webpackChunkName: "index" */ "../views/Front/Index.vue")
  },
  {
    path: "/terms-and-conditions",
    name: "Terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/Front/Terms.vue")
  },
  {
    path: "/affiliate",
    name: "Affiliate",
    component: () => import(/* webpackChunkName: "affiliate" */ "../views/Front/Affiliate.vue")
  },
  {
    path: "/how-to-play",
    name: "HowTo",
    component: () => import(/* webpackChunkName: "howtoplay" */ "../views/Front/HowTo.vue")
  },
  {
    path: "/account",
    name: "Account",
    component: () => import(/* webpackChunkName: "account" */ "../views/Front/Account.vue")
  },
  {
    path: "/support-ticket",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */ "../views/Front/Contact.vue")
  },
  {
    path: "/dany/admin-part",
    name: "Admin",
    component: () => import(/* webpackChunkName: "admin" */ "../views/Front/Admin.vue")
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const element = document.body
  let isShown = element.classList.contains("dialog-opened");
  if (isShown) {
    document.body.classList.remove('dialog-opened');
  }
  next();
});


/* router.beforeEach((to, from, next) => {
  if (typeof to.matched[0]?.components.default === 'function') {
    $eventHub.$emit('asyncComponentLoading', to) // Start progress bar
  }
  next()
})

router.beforeResolve((to, from, next) => {
  $eventHub.$emit('asyncComponentLoaded') // Stop progress bar
  next()
}) */

export default router;
